import LogTool from './tools/log-tool';
import Lib from './lib';

import { load } from 'recaptcha-v3';

import Cookies from 'js-cookie';
import LazyLoad from 'vanilla-lazyload';
import objectFitImages from 'object-fit-images';
//import DOMPurify from 'dompurify'; // https://web.dev/trusted-types/

import anime from 'animejs/lib/anime.es';
import IMask from 'imask';
//import inView from 'in-view'; // Fonctionne mais limité dans options data-*
import 'jquery-mask-plugin/dist/jquery.mask.min';
import 'jquery-validation/dist/jquery.validate.min.js';
//import 'jquery-validation/dist/additional-methods.min.js';
import 'jquery-inview/jquery.inview.min';
//import inView from 'in-view'; // Fonctionne mais limité dans options data-*

class Global {
	static isOffreSassImported = false;
	constructor() {
		this.loadSass();
		this.bindEvents();
	}
	loadSass(){
    (async () => {
			if ( document.querySelector('.offres') !== null ) {
				if (!Global.isOffreSassImported) {
					Global.isOffreSassImported = true;
					LogTool.log(`${Global.name}`,`Importing offre sass`)
					//let exports = await import('../css/pages/offre.scss');
					await import('../css/pages/offre.scss');
				}
				else {
					LogTool.log(`${Global.name}`,`Offre Sass already imported - skipping`)
				}
			}
			else {
				LogTool.info(`${Global.name}`,`.offres not found - skipping`);
			}
    })();
  }
	bindEvents() {
		const ref = this;
		(async () => {
			if ( document.querySelector('.js-tabs a') !== null ) {
				$('.js-tabs a').on('click', function (e) {
					//console.log("click js-tabs > " + e.currentTarget);
					e.preventDefault();
					
					//e.preventDefault();
					$(e.currentTarget).parent().addClass('is-active').siblings().removeClass('is-active');
					var target = $(e.currentTarget).attr('href');
					//console.log("target = " + target);
					$(target).show().addClass("is-open").siblings().hide().removeClass("is-open");
					Lib.scrollToTop($(target).offset().top - 40);
				});
			}

			//var hash = window.location.hash;
			var hash = location.hash.replace('#','');
			if (hash) {
				if ( hash == "newsletter") {
					ref.openPopup();
				}
				else {
					//var element = $('#' + hash.substr(2));
					var element = $('#' + hash);
					//LogTool.info(`${Global.name}`,"element = " + hash + " - length = "+element.length);
					if (element.length) {
						if ( hash == "soins-courants"
							|| hash == "pharmacie"
							|| hash == "optique"
							|| hash == "dentaire"
							|| hash == "hospitalisation"
							|| hash == "aides-auditives"
							) {
							//LogTool.info(`${Global.name}`,hash + " -> trigger(click)");
							$('.js-tabs a[href="#' + hash + '"]').trigger('click');
						}
						else {
							$('html, body').animate({
								scrollTop: element.offset().top
							}, 1000);
						}
					}
				}
			}

			$('.section').one('inview', function (e) {
				$(e.currentTarget).addClass('is-scrolled');
		
				// Anim grid
				if ($(this).hasClass('section-example')) {
					$('.grid__item').each(function (i, e) {
						setTimeout(function () {
							$(e).css({y: 10}).transition({
								opacity: 1,
								y: 0
							}, 400, function () {
								$(this).css('transform', 'none');
							});
						}, (i + 3) * 140);
					});
				}
			});
      
			if ( document.querySelector('.js-header-toggle') !== null ) {
				$('.js-header-toggle').on('click', function (e) {
					$(e.currentTarget).closest('.header').toggleClass('is-open');
				});
			}
			var eltMaskEmail = $("form .mask-email");//document.getElementsByClassName("mask-email");
			for (let k = 0; k < eltMaskEmail.length; k++) {
				$(eltMaskEmail[k]).mask("A", {
					translation: {
						"A": { pattern: /[\w@\-.+]/, recursive: true }
					}
				});
			}
		
			var eltMaskPhone = $("form .mask-phone");//document.getElementsByClassName("mask-phone");
			for (let k = 0; k < eltMaskPhone.length; k++) {
				$(eltMaskPhone[k]).mask('ABr00r00r00r00', {
					'translation': {
						'A': {pattern: /0/},
						'B': {pattern: /[1-9]/},
						'r': {
							pattern: /[-]/,
							fallback: '-'
						},
						'placeholder': "---------"
					}
				});
			}
		
			var $currentYear = new Date().getFullYear(),
				//$currentMonth = new Date().getMonth(),
				$maxYear = $currentYear-18,
				//$minChildYear = $currentYear-28,
				$minYear = 1920;
			var eltMaskBirthday = $("form .mask-birthday");//document.getElementsByClassName("mask-birthday");
			for (var i = 0; i < eltMaskBirthday.length; i++) {
				new IMask(eltMaskBirthday[i], {
					mask: Date,  // enable date mask
					// other options are optional
					pattern: 'd/`m/`YYYY',// 'Y-`m-`d',  // Pattern mask with defined blocks, default is 'd{.}`m{.}`Y'
					// optional interval options
					min: new Date($minYear, 0, 1),
					max: new Date($maxYear, 12, 31),
					autofix: true,  // defaults to `false`
					// also Pattern options can be set
					//lazy: false,
					placeholder: {
						show: 'always'
					},
					// define date -> str convertion
					format: function (date) {
						var day = date.getDate();
						var month = date.getMonth() + 1;
						var year = date.getFullYear();
				
						if (day < 10) day = "0" + day;
						if (month < 10) month = "0" + month;
						//console.log([year, month, day].join('-'));
						return [year, month, day].join('-');
					},
					// define str -> date convertion
					parse: function (str) {
						var dayMonthYear = str.split('/');
						return new Date(dayMonthYear[2], dayMonthYear[1] - 1, dayMonthYear[0]);
					}
				});
			}
		
			$("#gestionCookies").on("click", function(evt) {
				//console.log("click gestionCookies");
				tC.privacyCenter.showPrivacyCenter();
				evt.preventDefault();
			});
		
			$(".button-en-savoir-plus").on('click', function(evt) {
				evt.preventDefault();
				var target = $(this).attr('aria-controls');
				document.getElementById(target).style.display = "flex";
			});
			$(".en-savoir-plus").on('click', function(evt) {
				evt.preventDefault();
				if (evt.target !== this && ! evt.target.classList.contains('hide'))
					return;
				$(this).fadeOut();
			});
			// Force form to be (re)displayed after previous form submit
			// Check if necessary...
			$("#ctaNewsletter").on('click', function () {
				$('#popin-abonnement__result').css('display', 'none');
				$('#popin-abonnement__content').css('display', 'block');
			});
		
			$(".js-openPopup").on("click", function(e) {
				e.stopImmediatePropagation();
				ref.openPopup();
				return false;
			});
			
			$(".js-closePopup").on("click", function(e) {
				e.stopImmediatePropagation();
				ref.closePopup();
				return false;
			});

			$("form.form-abonnement").validate({
				//errorElement: "span",
				errorClass: "has-error",
				highlight: function (element, errorClass) {
					$(element).closest('.form__group').addClass(errorClass);
				},
				unhighlight: function (element, errorClass) {
					$(element).closest('.form__group').removeClass(errorClass);
				},
				errorPlacement: function (error, element) {
					element.closest('.form__group').append(error.addClass('form__error'));
				}
			});

			$('form :input:not([name="num_certificat"]):not(select):not([type="checkbox"]):not([type="file"])').on('focus', function () {
				if ($(this).parents('.form__group').length > 0) {
					Lib.scrollToTop($(this).parents('.form__group').offset().top - 80);
				}
			});
		
			// Form 'abonnement' submit button
			$('#newsletter .form__submit').on('click', function (e) {

				$('#global-abonnement__error').hide().empty();

				if ($('.form-abonnement').valid()) {
					Lib.showLoader("Traitement de votre demande");

					var honey = $('#mail-confirmation-abonnement').val();
					// Build data to send via AJAX
					var data = {
						csrf_token: $('#abo-csrf_token').val(),
						email: $('#mail-abonnement').val(),
						job: $('#job-abonnement').val(),
						service_notice: $('#info-abonnement').is(':checked'),
						legal_terms: $('#cgu-abonnement').is(':checked'),
						database: 'newsletter',
						//phrase: $('#phrase-abonnement').val(),
						honey: honey
					};
					// Check honeypot
					if (honey != '') {
						Lib.doAjax({
							url: '/api/honey',
							data: JSON.stringify(data)
						}).then(
							(data) => handleHoneySuccess(data),
							(error) => handleHoneyError(error)
						).finally(function () {
							Lib.hideLoader();
						});
						function handleHoneySuccess(response) {
							$('#phrase-reclamation').val('');
							$('#popin-abonnement__result .popin__sub').html(response.message);
							//$('#popin-abonnement__result .popin__sub').innerHTML = DOMPurify.sanitize(response.message, {RETURN_TRUSTED_TYPE: true});
							$('#popin-abonnement__result').css('display', 'block');
							$('#popin-abonnement__content').css('display', 'none');
							Lib.scrollToTop($("#newsletter").offset().top);
						}
						function handleHoneyError(promise) {
							let response = promise.responseJSON;
							LogTool.error(`${Global.name}`,JSON.stringify(response));
							// Do nothing for bots
							//console.log(jqXHR);
						}
					}
					else {
						let sitekey = $(this).data('sitekey');

						// Check Google reCAPTCHA
						load(sitekey, {
							useRecaptchaNet: true,
							autoHideBadge: true
						}).then((recaptcha) => {
							//recaptcha.execute('reCAPTCHA').then((token) => {
							recaptcha.execute('newsletter_captcha').then((token) => {
								//console.log(token) // Will print the token
								var dataCaptcha = {
									'g-recaptcha-response': token
								};
								$.ajax({
									url: '/api/recaptcha',
									method: 'POST',
									data: JSON.stringify(dataCaptcha),
									dataType: 'json'
								}).done(function (response) {
									//console.log(response);
									// Si réponse OK du recaptcha
									if ('recaptcha' in response ) {
										Lib.doAjax({
											url: '/api/callback',
											data: JSON.stringify(data)
										}).then(
											(data) => handleCallbackSuccess(data),
											(error) => handleCallbackError(error)
										).finally(function () {
											Lib.hideLoader();
										});
										function handleCallbackSuccess(response) {
											Lib.scrollToTop($("#newsletter").offset().top);
											$('#popin-abonnement__result .popin__sub').html(response.message);
											//$('#popin-abonnement__result .popin__sub').innerHTML = DOMPurify.sanitize(response.message, {RETURN_TRUSTED_TYPE: true});
											$('#popin-abonnement__result').css('display', 'block');
											$('#popin-abonnement__content').css('display', 'none');
										}
										function handleCallbackError(promise) {
											let response = promise.responseJSON;
											LogTool.error(`${Global.name} /api/recaptcha`,JSON.stringify(response));
				
											if ('error' in response && 'code' in response.error) {
												if (response.error.code == 4) {
													if ('extra' in response.error && 'field' in response.error.extra) {
														$('#global-abonnement__error').html('Merci de bien vouloir remplir le champ ' + response.error.extra.field + '.').show();
													} else {
														$('#global-abonnement__error').html('Merci de bien vouloir remplir tous les champs obligatoires.').show();
													}
												}
												else {
													$('#global-abonnement__error').html(response.error.message).show();
												}
											}
											else {
												$('#global-abonnement__error').html(response).show();
											}
											Lib.scrollToTop($('#global-abonnement__error').offset().top-300);
										}
									}
									else {
										LogTool.error(`${Global.name} /api/recaptcha`,JSON.stringify(response));	
										$('#global-abonnement__error').html(response.error.message).show();
										Lib.scrollToTop($('#global-abonnement__error').offset().top-300);
										Lib.hideLoader();
									}
								}).fail(function (jqXHR) {
									LogTool.error(`${Global.name} /api/recaptcha`,JSON.stringify(jqXHR));
								});
							})
						})
					}
				}
				else {
					var first = $('.form-abonnement').find('.has-error').first();
					if (first.length) {
						var elementOffset = first.offset().top - 20;
						if (first.parents('.form__group').offset() !== undefined) {
							elementOffset = first.parents('.form__group').offset().top - 20;
						}
						Lib.scrollToTop(elementOffset);
					}
				}
				e.preventDefault();
			});

			if ($(".header__anim__line")) {
				let lfirst = $(".header__anim__line.first");
				lfirst.css({'transform': 'scaleY(1)'});
		
				let llast = $(".header__anim__line.last");
				if (llast) {
					let llParent = llast.parents('section');
					let h = parseInt(llParent.height())-parseInt(llParent.find('.header__title span').height());
		
					let opts = {
						//'height': (h == 0  ? '180' : (h*1.5))+'px',
						'height': '100px',
						'transform': 'scaleY(1)'
					}
					
					if (h < 500) {
						//opts['height'] = "160px";
						opts['margin-top'] = "-60px";
					}
					if ( llast.attr('data-delay') ) {
						opts['transition-delay'] = llast.attr('data-delay')+'ms';
					}
					
					//console.log(h);
					llast.css(opts);
				}
			}

      if ( document.querySelector('.captcha_reload') !== null ) {
        $(".captcha_reload").on("click", function() {
          var elt = $(this).data("captcha");
          Lib.reload_captcha(elt);
          return false;
        });
      }
    
      if ( document.querySelector('.cryptedmail') !== null ) {
        $(".cryptedmail").on('click', function(evt) {
          //window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld;
          var url = "mailto:"+this.dataset.name+"@"+this.dataset.domain;
          window.location.href = url;
          //window.open(url);
          evt.preventDefault();
          //return false;
        });
      }
			
			new LazyLoad({
				//use_native: true
			});

			objectFitImages("img");

      ref.setAccessibility();

      if (window.navigator.userAgent.match(/(MSIE|Trident)/)) {
        // IE11 Patch car css non reconnu alors que l'attribut transform="translate(0,2px)" est fonctionnel
        var rect = document.querySelector('rect'),
          transform = getComputedStyle(rect).getPropertyValue('transform');
        if ( transform !== "none" ) {
          //console.log('IE patch: svg > rect - transform attribute -> ' + transform);
          rect.setAttribute('transform', transform);
        }
      }
      var bUserScroll = false;
      document.addEventListener('scroll',
        function() {
          bUserScroll = true;
        },
        { passive: true }
      );
      setInterval(function() {
        if ( bUserScroll 
          && document.documentElement.getAttribute("data-font-size") == "normal" ) {
          bUserScroll = false;
          var cur_pos = $(window).scrollTop();
    
          /* Position of the returnToTop icon above the footer line */
          if (cur_pos > 1200) {
            /*
            var topOfFooter = $('#footer').position().top,
              scrollDistanceFromTopOfDoc = $(document).scrollTop() + window.innerHeight,
              scrollDistanceFromTopOfFooter = scrollDistanceFromTopOfDoc - topOfFooter; 
            if (scrollDistanceFromTopOfDoc > topOfFooter) {
              $('#return-to-top').css('margin-bottom',  0 + scrollDistanceFromTopOfFooter);
            }
            else  {
              $('#return-to-top').css('margin-bottom', 0);
            }
            */
            $("#return-to-top").removeClass('out').addClass('in').show();
          }
          else {
            $("#return-to-top.in").addClass('out');
          }
        }
      }, 100);
      $("#return-to-top").on("click", Lib.scrollToTop);
    
			if (ref.isIE() && $(".warning-ie").length === 0) {
				const warningIe = `
					<div class="warning-ie">
							<div class="wrapper" style="opacity: 1;">
									<button class="close cta-ie-close"></button>
									<span>FERMER</span>
							</div>
							Attention, il semblerait que vous utilisiez Internet Explorer !<br/>
							<strong>Ce navigateur n'est plus maintenu à jour par Microsoft depuis le 15 juin 2022.</strong><br/>
							Merci d'utiliser un navigateur sécurisé et plus moderne : Chrome, Firefox, Opera ou Edge...
					</div>`;
		
				const $warningIe = $(warningIe);
				
				$warningIe.appendTo("#global").css("visibility", "visible").addClass("go")
					.on("click", ".wrapper", function() {
							$warningIe.removeClass("go").css("visibility", "hidden");
					});
			}
/*
      if ( ref.isIE() ) {
        if ( ! $(".warning-ie").length ) {
          var warningIe = '<div class="warning-ie">';
          warningIe+= ' <div class="wrapper" style="opacity: 1;">';
          warningIe+= '  <button class="close cta-ie-close"></button>';
          warningIe+= '  <span>FERMER</span>';
          warningIe+= ' </div>';
          warningIe+= ' Attention, il semblerait que vous utilisiez Internet Explorer !<br/>';
          warningIe+= ' <strong>Ce navigateur n\'est plus maintenu à jour par Microsoft depuis le 15 juin 2022.</strong><br/>';
          warningIe+= ' Merci d\'utiliser un navigateur sécurisé et plus moderne : Chrome, Firefox, Opera ou Edge...';
          warningIe+= '</div>';
          $("#global").append(warningIe);
          $(".warning-ie").css("visibility", "visible").addClass("go");
          $(".warning-ie div.wrapper").on("click", function() {
            $(".warning-ie").removeClass("go").css("visibility", "hidden");
          });
        }
      }
*/
			if ( document.querySelector('.js-slider-cover') !== null ) {
				(async () => {
					await import('slick-carousel/slick/slick.min.js');
					$('.js-slider-cover').slick({
						arrows: false,
						draggable: false,
						touchMove: false,
						fade: true,
						autoplay: true,
						autoplaySpeed: 5000,
						speed: 800
					});
				})();
			}
		})();
	}
	showPopup(idD) {
		let showPopup = anime.timeline({
			autoplay: false
		})
		.add({
			targets: '.section-newsletter-details .popupDetails__closeWrapper',
			opacity: [0, 1],
			duration: 600,
			easing: 'cubicBezier(.215,.61,.355,1)'
		})
		.add({
			targets: "#" + idD + " .popupTitle",
			translateY: [30, 0],
			opacity: [0, 1],
			scale: [0.95, 1],
			duration: 400,
			easing: 'cubicBezier(.215,.61,.355,1)'
		})
		.add({
			targets: "#" + idD + " .popupContent",
			opacity: [0, 1],
			translateY: [30, 0],
			duration: 400,
			delay: anime.stagger(80),
			easing: 'cubicBezier(.215,.61,.355,1)'
		})
		return showPopup;
	}
	hidePopup(idD) {
		let hidePopup = anime.timeline({
			autoplay: false
		})
		.add({
			targets: '.section-newsletter-details .popupDetails__closeWrapper',
			opacity: [1, 0],
			duration: 500,
			easing: 'cubicBezier(.215,.61,.355,1)'
		})
		.add({
			targets: "#" + idD + " .popupContent",
			opacity: [1, 0],
			translateY: [0, 30],
			duration: 300,
			delay: anime.stagger(80),
			easing: 'cubicBezier(.215,.61,.355,1)'
		})
		.add({
			targets: "#" + idD + " .popupTitle",
			translateY: [0, 30],
			opacity: [1, 0],
			scale: [1, 0.95],
			duration: 300,
			easing: 'cubicBezier(.215,.61,.355,1)'
		})
		return hidePopup;
	}
	openPopup() {
    const ref = this;
		//console.log("enter openPopup");
		//$('body').addClass('overflow-hidden');
		//$("#newsletter").addClass('active');
		let section = $(".section-newsletter-details");
		let idD = "details-newsletter";
		let popup = $("#" + idD);
	
		$("#ctaNewsletter").attr("aria-expanded", true);
		$(".section-newsletter").slideUp(500, function() {  
			popup.addClass("transpa").removeClass("hidden");
			section
				.hide()
				.removeClass("hidden")
				.slideDown(500, function() {
					section.attr("aria-hidden", false);
					// Scroll to section
					let scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
					//let eTop = parseInt(section.offset().top) + 320;
					let eTop = parseInt(section.offset().top);
					anime({
						targets: scrollElement,
						//scrollTop: eTop,
						duration: 350,
						easing: 'cubicBezier(.215,.61,.355,1)',
						update: function() {
							window.scroll(0, eTop)
						}
						//update: () => window.scroll(0, eTop)
					});
					popup.removeClass("transpa");
					
					let showPop = ref.showPopup(idD);
					showPop.play();
					
					popup.attr("aria-hidden", false).addClass("visible");
				});
		});
	}
	closePopup() {
    const ref = this;
		//console.log("enter displayNewsletter");
		//$('body').addClass('overflow-hidden');
		//$("#newsletter").addClass('active');
		let section = $(".section-newsletter");
		let idD = "details-newsletter";
		let popup = $("#" + idD);
	
		var showSectionNl = function() {
			popup.attr("aria-hidden", true).removeClass("visible");
	
			$(".section-newsletter-details").slideUp(500, function() {
				//popup.addClass("hidden");
				$(".section-newsletter-details").addClass("hidden").attr("aria-hidden", true);
				section.slideDown(500, function() {
					let scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
					let eTop = parseInt(section.offset().top);
					location.hash = '';
					anime({
							targets: scrollElement,
							scrollTop: eTop,
							duration: 350,
							easing: 'cubicBezier(.215,.61,.355,1)'
					});
				});
			});
		}
	
		let hidePop = ref.hidePopup(idD);
		hidePop.play();
		if ( hidePop.finished ) {
			hidePop.finished.then(showSectionNl);
		}
		else {
			setTimeout(showSectionNl, 1200);
		}
		return false;
	}
  isIE() {
    return !!window.navigator.userAgent.match(/MSIE|Trident/);
  }
  setAccessibilityBadge(aObject) {
		var iElement = 0;
		for (var key in aObject) {
			if ( (key == 'animations' && aObject[key] == "0")
				|| (key != 'animations' && aObject[key] != "0") ) {
				iElement++;
			}
		}
		if ( iElement > 0 ) {
			$("button .badge-accessibility").html(iElement); //DOMPurify.sanitize(iElement, {RETURN_TRUSTED_TYPE: true});
		}
		else {
			$("button .badge-accessibility").html(""); //DOMPurify.sanitize("", {RETURN_TRUSTED_TYPE: true});;
		}
	}
  setAccessibility() {
		const ref = this;
    var cookie_name = 'Anticipa_accessibilite';
    var aAccessibility = {};
  
    if (Cookies.get(cookie_name) !== undefined) {
      aAccessibility = JSON.parse(Cookies.get(cookie_name));
      ref.setAccessibilityBadge(aAccessibility);
    }
    else {
      aAccessibility['dyslexic'] = "0";
      aAccessibility['interlignes'] = "0";
      aAccessibility['darkmode'] = "0";
      aAccessibility['fontsize'] = "0";
      aAccessibility['animations'] = "1";
    }
    if (aAccessibility['dyslexic'] == "1") {
      $(".police").prop('checked', true);
      $('body').addClass("dyslexic");
    }
    else {
      $('body').removeClass("dyslexic");
    }
    if (aAccessibility['interlignes'] == "1") {
      $(".interlignes").prop('checked', true);
      $('body').addClass("interlignes");
    }
    else {
      $('body').removeClass("interlignes");
    }
    if (aAccessibility['animations'] == "1") {
      $(".animations").prop('checked', true);
      $('body').removeClass("noeffects");
    }
    else {
      $(".animations").prop('checked', false);
      $('body').addClass("noeffects");
    }
    if (aAccessibility['darkmode'] == "1") {
      $(".btn-accessibility-contrast").removeClass('active');
      $(".btn-accessibility-contrast[data-accessibility-value=inverted]").addClass('active');
      document.documentElement.setAttribute("data-theme", "dark");
			document.documentElement.setAttribute("data-bs-theme", "dark");
    }
    else {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        $(".btn-accessibility-contrast").removeClass('active');
        $(".btn-accessibility-contrast[data-accessibility-value=inverted]").addClass('active');
        document.documentElement.setAttribute('data-theme', "dark");
				document.documentElement.setAttribute('data-bs-theme', "dark");
        aAccessibility['darkmode'] = "1";
      }
      else {
        $(".btn-accessibility-contrast[data-accessibility-value=default]").addClass('active');
        document.documentElement.setAttribute("data-theme", "light");
				document.documentElement.setAttribute('data-bs-theme', "light");
      }    
    }
    if (aAccessibility['fontsize'] == "xlarge") {
      $(".btn-accessibility-fontsize").removeClass('active');
      $(".btn-accessibility-fontsize[data-accessibility-value=xlarge]").addClass('active');
      document.documentElement.setAttribute("data-font-size", "xlarge");
    }
    else if (aAccessibility['fontsize'] == "large") {
      $(".btn-accessibility-fontsize").removeClass('active');
      $(".btn-accessibility-fontsize[data-accessibility-value=large]").addClass('active');
      document.documentElement.setAttribute("data-font-size", "large");
    }
    else {
      $(".btn-accessibility-fontsize[data-accessibility-value=default]").addClass('active');
      document.documentElement.setAttribute("data-font-size", "normal");
    }
    $("input.police[type=checkbox]").on("change", function() {
      if ( $(this).is(':checked') ) {
        $('body').addClass("dyslexic");
        aAccessibility['dyslexic'] = "1";
      }
      else {
        $('body').removeClass("dyslexic");
        aAccessibility['dyslexic'] = "0";
      }
      ref.setAccessibilityBadge(aAccessibility);
      Cookies.set(cookie_name, JSON.stringify(aAccessibility), {
        sameSite: 'strict',
        expires: 365,
        secure: true
      });
    });
    $("input.interlignes[type=checkbox]").on("change", function() {
      if ( $(this).is(':checked') ) {
        $('body').addClass("interlignes");
        aAccessibility['interlignes'] = "1";
      }
      else {
        $('body').removeClass("interlignes");
        aAccessibility['interlignes'] = "0";
      }
      ref.setAccessibilityBadge(aAccessibility);
      Cookies.set(cookie_name, JSON.stringify(aAccessibility), {
        sameSite: 'strict',
        expires: 365,
        secure: true
      });
    });
    $("input.animations[type=checkbox]").on("change", function() {
      if ( $(this).is(':checked') ) {
        $('body').removeClass("noeffects");
        aAccessibility['animations'] = "1";
      }
      else {
        $('body').addClass("noeffects");
        aAccessibility['animations'] = "0";
      }
      ref.setAccessibilityBadge(aAccessibility);
      Cookies.set(cookie_name, JSON.stringify(aAccessibility), {
        sameSite: 'strict',
        expires: 365,
        secure: true
      });
    });
    /*$("input.fontsize[type=checkbox]").on("change", function() {
      if ( $(this).is(':checked') ) {
        document.documentElement.setAttribute("data-font-size", "large");
        aAccessibility['fontsize'] = "1";
      }
      else {
        document.documentElement.setAttribute("data-font-size", "normal");
        aAccessibility['fontsize'] = "0";
      }
      ref.setAccessibilityBadge(aAccessibility);
      Cookies.set(cookie_name, JSON.stringify(aAccessibility), {
        sameSite: 'strict',
        expires: 365,
        secure: true
      });
    });*/
    $(".btn-accessibility-fontsize").on("click", function() {
      $(".btn-accessibility-fontsize").removeClass('active');
      if ( $(this).attr('data-accessibility-value') == "xlarge" ) {
        $(".btn-accessibility-fontsize[data-accessibility-value=xlarge]").addClass('active');
        aAccessibility['fontsize'] = "xlarge";
        document.documentElement.setAttribute("data-font-size", "xlarge");
      }
      else if ( $(this).attr('data-accessibility-value') == "large" ) {
        $(".btn-accessibility-fontsize[data-accessibility-value=large]").addClass('active');
        aAccessibility['fontsize'] = "large";
        document.documentElement.setAttribute("data-font-size", "large");
      }
      else {
        $(".btn-accessibility-fontsize[data-accessibility-value=default]").addClass('active');
        aAccessibility['fontsize'] = "0";
        document.documentElement.setAttribute("data-font-size", "normal");
      }
      ref.setAccessibilityBadge(aAccessibility);
      Cookies.set(cookie_name, JSON.stringify(aAccessibility), {
        sameSite: 'strict',
        expires: 365,
        secure: true
      });
    });
    $(".btn-accessibility-contrast").on("click", function() {
      $(".btn-accessibility-contrast").removeClass('active');
      if ( $(this).attr('data-accessibility-value') == "inverted" ) {
        $(".btn-accessibility-contrast[data-accessibility-value=inverted]").addClass('active');
        aAccessibility['darkmode'] = "1";
        document.documentElement.setAttribute("data-theme", "dark");
				document.documentElement.setAttribute("data-bs-theme", "dark");
      }
      else {
        $(".btn-accessibility-contrast[data-accessibility-value=default]").addClass('active');
        aAccessibility['darkmode'] = "0";
        document.documentElement.setAttribute("data-theme", "light");
				document.documentElement.setAttribute("data-bs-theme", "light");
      }
      ref.setAccessibilityBadge(aAccessibility);
      Cookies.set(cookie_name, JSON.stringify(aAccessibility), {
        sameSite: 'strict',
        expires: 365,
        secure: true
      });
    });
  }
}

export default new Global();
