import LogTool from './tools/log-tool';
import Lib from './lib';

import LazyLoad from 'vanilla-lazyload';

$.fn.isOnScreen = function(test){
	var height = this.outerHeight();
	var width = this.outerWidth();
	if(!width || !height){
		return false;
	}
	var win = $(window);
	var viewport = {
		top : win.scrollTop(),
		left : win.scrollLeft()
	};
	viewport.right = viewport.left + win.width();
	viewport.bottom = viewport.top + win.height();

	var bounds = this.offset();
	bounds.right = bounds.left + width;
	bounds.bottom = bounds.top + height;
	
	var deltas = {
		top : viewport.bottom - bounds.top,
		left: viewport.right - bounds.left,
		bottom: bounds.bottom - viewport.top,
		right: bounds.right - viewport.left
	};

	if(typeof test == 'function') {
		return test.call(this, deltas);
	}
	
	return deltas.top > 0
		&& deltas.left > 0
		&& deltas.right > 0
		&& deltas.bottom > 0;
};

class PublicationsLast {
  static isSassImported = false;
  constructor(){
		this.page = document.querySelector('#publications-last');
    if (this.page !== null) {
      //this.loadSass();
      this.bindEvents();
		}
  }
  bindEvents() {
		const ref = this;
		(async () => {
      var render_publications = $("#publications-last"),
      bRunPublications = false,
      bRunPublicationsCalled = false;
      document.addEventListener('scroll',
        function() {
          bRunPublications = true;
        },
        { passive: true }
      );
      setInterval(function() {
        if ( bRunPublications && ! bRunPublicationsCalled) {
          bRunPublications = false;
          
          if ( render_publications.isOnScreen() ) {
            bRunPublicationsCalled = true;
            ref.loadSass();
            ref.setupPublications();
          }
        }
      }, 50);
    })();
	}
  loadSass(){
    (async () => {
			if (!PublicationsLast.isSassImported) {
				PublicationsLast.isSassImported = true;
				LogTool.log(`${PublicationsLast.name}`,`Importing publications sass`)
        await import('../css/pages/publications.scss');
			}
			else {
				LogTool.log(`${PublicationsLast.name}`,`Sass already imported - skipping`)
			}
    })();
  }
  setupPublications() {
    var lazyLoadInstance = new LazyLoad();
  
    Lib.doAjax({
      url: '/api/publications?limit=3',
      type: 'GET'
    }).then(
      (data) => handleSuccess(data),
      (error) => handleError(error)
    );
    function handleSuccess(aResponse) {
      if (aResponse.error !== '') {
        $("#publications-last").html("");
        $("#error-msg .exclamation-content").html(aResponse.error);
        $("#error-msg").removeClass("d-none").addClass("d-flex");
        $("#error-msg .block-exclamation").addClass("go");
        $(".loader-page").hide();
      }
      else {
        var url_strapi = aResponse.url_strapi;
        var access_token = aResponse.access_token;
        var jsonStrapi = aResponse.articles;
  /*
        var displayElementsBeforeChatGpt = function (jsonData) {
          //console.info("displayElements");
          //console.log(jsonData);
          $("#publications-last").html("<div class='loader-page m-auto'><span></span><span></span><span></span></div>");
          var aHtmlPublications = []; 
          for (let idx = 0; idx < jsonData.length; idx++) {
            let obj = jsonData[idx];
  
            var routeArticleDetail = '/publications/' + obj.id + '/' + Lib.slugify(obj.title);
            var routeArticlesBycategory = '/publications?category=' + Lib.urlencode(obj.category);
  
            var imgUrlLowQuality = "/build/img/img_cnp_locaux_530px_lowquality.jpg";
            var imgUrl = "/build/img/bg-cnp-assurances_530px.jpg";
            var imgWidth = "530";
            var imgHeight = "470";
            var imgAlt = "CNP Assurances";
            if (obj.image != null && !$.isEmptyObject(obj.image)) {
              if (obj.image.formats != null && !$.isEmptyObject(obj.image['formats'])) {
                if (obj.image.formats.medium != null && !$.isEmptyObject(obj.image['formats']['medium'])) {
                  imgUrl = url_strapi + obj.image['formats']['medium']['url'] + '?access_token=' + access_token;
                  imgUrlLowQuality = imgUrl;
                  imgWidth = obj.image['formats']['medium']['width'];
                  imgHeight = obj.image['formats']['medium']['height'];
                }
                else if (obj.image.formats.small != null && !$.isEmptyObject(obj.image['formats']['small'])) {
                  imgUrl = url_strapi + obj.image['formats']['small']['url'] + '?access_token=' + access_token;
                  imgUrlLowQuality = imgUrl;
                  imgWidth = obj.image['formats']['small']['width'];
                  imgHeight = obj.image['formats']['small']['height'];
                }
                else if (obj.image.formats.thumbnail != null && !$.isEmptyObject(obj.image['formats']['thumbnail'])) {
                  imgUrlLowQuality = url_strapi + obj.image['formats']['thumbnail']['url'] + '?access_token=' + access_token;
                }
              }
              else {
                imgUrl = url_strapi + obj.image['url'] + '?access_token=' + access_token;
                if ( obj.image['width'] != null && obj.image['width'] !== "null") {
                  imgWidth = obj.image['width'];
                }
                if ( obj.image['height'] != null && obj.image['height'] !== "null") {
                  imgHeight = obj.image['height'];
                }
              }
              imgAlt = obj.image['alternativeText'];  
            }
            
            var htmlArticle = '<article class="publication hp d-flex align-items-start flex-column mx-2 mb-4 animated fadeInUpShort ' + (idx < 3 ? 'go delay-' + ((idx+1)*250) : '')+ '" data-id="' + (idx+1) + '">' +
              '<div class="publication-image d-flex align-self-start align-items-center">' +
                '<div class="img lazy" data-bg="' + imgUrl + '"></div>' +
                //'<img class="lazy" loading="lazy" data-src="' + imgUrl + '" src="' + imgUrlLowQuality + '" width="' + imgWidth + '" height="' + imgHeight + '" alt="' + imgAlt + '" />' +
              '</div>' +
              '<div class="d-flex flex-column p-4 publication-content">' +
                '<div class="d-flex align-items-start justify-content-between w-100">' +
                  '<p class="publication-date txt-blue mb-2">Publié le ' + new Date(obj.creation).toLocaleDateString('fr-FR') + '<br/>' +
                    '<a class="cta-link-categ" href="' + routeArticlesBycategory + '"><svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><rect x="-0.175781" y="-1.2353" width="25.4118" height="25.4118" mask="url(#icon-category)" transform="translate(0 -2)"></rect></svg>' + obj.category + '</a>' +
                  '</p>' +
                '</div>' +
                '<h3 class="publication-title txt-blue h6 d-flex flex-start flex-wrap w-100 mt-1">' + Lib.shorten(obj.title, 80) + '</h3>' +
                '<a class="mt-auto cta-link-bloc cta-lire-la-suite blue" href="' + routeArticleDetail + '">Lire la suite<svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"><rect width="24" height="24" mask="url(#icon-arrow-right)"></rect></svg></a>' +
              '</div>' +
            '</article>';
  
            aHtmlPublications.push(htmlArticle);
  
            if (idx == jsonData.length - 1) {
              $("#publications-last").html(aHtmlPublications.join(''));
              lazyLoadInstance.update();
              //objectFitImages('.publication-image img');
              //$('.animatedParent').appear();
            }
          }
        }
  */
        /**
         * 27/11/2023 : Refacto thanks to ChatGPT4
         * - Consolidate image URL logic: Combine common logic for constructing image URLs to reduce redundancy.
         * - Refactor the HTML generation: Break down the HTML generation into smaller, more readable parts.
         * 
         */
        var displayElements = function (jsonData) {
          $("#publications-last").html("<div class='loader-page m-auto'><span></span><span></span><span></span></div>");
        
          const aHtmlPublications = [];
        
          for (let idx = 0; idx < jsonData.length; idx++) {
            const obj = jsonData[idx];
            const routeArticleDetail = `/publications/${obj.id}/${Lib.slugify(obj.title)}`;
            const routeArticlesByCategory = `/publications?category=${Lib.urlencode(obj.category)}`;
        
            let imgUrl = "/build/img/bg-cnp-assurances_530px.jpg";
            let imgWidth = "530";
            let imgHeight = "470";
            let imgAlt = "CNP Assurances";
        
            if (obj.image && obj.image.formats) {
              const format = obj.image.formats.medium || obj.image.formats.small || obj.image.formats.thumbnail;
        
              if (format) {
                imgUrl = `${url_strapi}${format.url}?access_token=${access_token}`;
                imgWidth = format.width;
                imgHeight = format.height;
              }
            } else if (obj.image) {
              imgUrl = `${url_strapi}${obj.image.url}?access_token=${access_token}`;
              imgWidth = obj.image.width || imgWidth;
              imgHeight = obj.image.height || imgHeight;
              imgAlt = obj.image.alternativeText || imgAlt;
            }
        
            const htmlDate = `<p class="publication-date txt-blue mb-2">Publié le ${new Date(obj.creation).toLocaleDateString('fr-FR')}<br/>
              <a class="cta-link-categ" href="${routeArticlesByCategory}">
                <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24">
                  <rect x="-0.175781" y="-1.2353" width="25.4118" height="25.4118" mask="url(#icon-category)" transform="translate(0 -2)"></rect>
                </svg>${obj.category}
              </a>
            </p>`;
        
            const htmlTitle = `<h3 class="publication-title txt-blue h6 d-flex flex-start flex-wrap w-100 mt-1">${Lib.shorten(obj.title, 80)}</h3>`;
        
            const htmlArticle = `<article class="publication hp d-flex align-items-start flex-column mx-2 mb-4 animated fadeInUpShort ${idx < 3 ? 'go delay-' + ((idx + 1) * 250) : ''}" data-id="${idx + 1}">
              <div class="publication-image d-flex align-self-start align-items-center">
                <div class="img lazy" data-bg="${imgUrl}"></div>
              </div>
              <div class="d-flex flex-column p-4 publication-content">
                <div class="d-flex align-items-start justify-content-between w-100">
                  ${htmlDate}
                </div>
                ${htmlTitle}
                <a class="mt-auto cta-link-bloc cta-lire-la-suite blue" href="${routeArticleDetail}">Lire la suite
                  <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24">
                    <rect width="24" height="24" mask="url(#icon-arrow-right)"></rect>
                  </svg>
                </a>
              </div>
            </article>`;
        
            aHtmlPublications.push(htmlArticle);
        
            if (idx === jsonData.length - 1) {
              $("#publications-last").html(aHtmlPublications.join(''));
              lazyLoadInstance.update();
            }
          }
        };
        
        displayElements(jsonStrapi);
      }
    }
    function handleError(promise) {
      let response = promise.responseJSON;
      LogTool.error(`Publications-last`,JSON.stringify(response));
      LogTool.error(`Publications-last`,'strapi feeds error');
      $("#publications-last").html("");
      $("#error-msg .exclamation-content").html("Le service de publication est momentanément indisponible");
      $("#error-msg").removeClass("d-none").addClass("d-flex");
      $("#error-msg .block-exclamation").addClass("go");
      $(".loader-page").hide();
    }
  }
}

export default new PublicationsLast();